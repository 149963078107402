import { importProvidersFrom } from "@angular/core";
import { AppComponent } from "./app/app.component";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
	provideRemoteConfig,
	getRemoteConfig,
} from "@angular/fire/remote-config";
import {
	provideFunctions,
	getFunctions,
	connectFunctionsEmulator,
} from "@angular/fire/functions";
import {
	provideFirestore,
	getFirestore,
	connectFirestoreEmulator,
} from "@angular/fire/firestore";
import { provideAuth, getAuth, connectAuthEmulator } from "@angular/fire/auth";
import { environment } from "./environments/environment";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import {
	withInterceptorsFromDi,
	provideHttpClient,
} from "@angular/common/http";
import { AppRoutingModule } from "./app/app-routing.module";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			BrowserModule,
			AppRoutingModule,
			provideFirebaseApp(() => initializeApp(environment.firebase)),
			provideAuth(() => {
				const auth = getAuth();
				if (environment.useEmulators) {
					connectAuthEmulator(auth, "http://0.0.0.0:9099", {
						disableWarnings: true,
					});
				}
				return auth;
			}),
			provideFirestore(() => {
				const firestore = getFirestore();
				if (environment.useEmulators) {
					connectFirestoreEmulator(firestore, "0.0.0.0", 8080);
				}
				return firestore;
			}),
			provideFunctions(() => {
				const functions = getFunctions(undefined, environment.gcpRegion);
				if (environment.useEmulators) {
					connectFunctionsEmulator(functions, "0.0.0.0", 5001);
				}
				return functions;
			}),
			provideRemoteConfig(() => getRemoteConfig()),
		),
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimations(),
	],
}).catch((err) => console.error(err));
