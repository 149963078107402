import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard, redirectUnauthorizedTo } from "@angular/fire/auth-guard";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["sign-in"]);

const routes: Routes = [
	{
		path: "",
		pathMatch: "full",
		redirectTo: "dash",
	},
	{
		path: "dash",
		loadChildren: () => import("./dash/dash.module").then((m) => m.DashModule),
		canActivate: [AuthGuard],
		data: { authGuardPipe: redirectUnauthorizedToLogin },
	},
	{
		path: "sign-in",
		loadComponent: () =>
			import("./sign-in/sign-in.component").then((c) => c.SignInComponent),
	},
	{
		path: "sign-in-with-magic-link",
		loadComponent: () =>
			import(
				"./sign-in-with-magic-link/sign-in-with-magic-link.component"
			).then((c) => c.SignInWithMagicLinkComponent),
	},
	{
		path: "**",
		pathMatch: "full",
		loadComponent: () =>
			import("./page-not-found/page-not-found.component").then(
				(m) => m.PageNotFoundComponent,
			),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
